// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-editorial-page-js": () => import("./../../../src/templates/editorialPage.js" /* webpackChunkName: "component---src-templates-editorial-page-js" */),
  "component---src-templates-elements-js": () => import("./../../../src/templates/elements.js" /* webpackChunkName: "component---src-templates-elements-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/postsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/productsPage.js" /* webpackChunkName: "component---src-templates-products-page-js" */)
}

